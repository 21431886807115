export const SIGNUP = "/signup";
export const SIGNUP2 = "/signup-2";
export const SIGNUP3 = "/signup-3";
export const CONTACT = "/contact";
export const LANDING = "/";
export const BUSINESSES = "/businesses";
export const PROMOTE = "/promote";
export const TEAM = "/team";
export const CRITERIA = "/criteria";
export const ABOUT = "/about";
export const DETAILS = "/details";

